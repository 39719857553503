<template>
  <div>
    <div class="v_upinfo">
      <div class="bili-avatar">
        <el-avatar class="img" :src="user.userImg"></el-avatar>
      </div>
      <div class="up-info_right">
        <div class="username">
          {{ user.username }}
        </div>
        <div class="userIntroduce">
          {{ user.userIntroduce }}
        </div>
      </div>
    </div>
    <div class="page">
      <div  v-for="(item,index) in video"  :key="index" class="page-li" @click.stop="change(item)" :class="item.id===id?'blue':'white'">
        <span  >{{ index }}</span>
        <span class="title"> {{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "right",
  props: ["id", "user", "video"],
  data() {
    return {
      // url:""
    }
  },
  methods: {
    change(item) {
      console.log(item)
      this.$emit("clickUrl", item.id)
    }
  },
  mounted() {

  },

}
</script>

<style scoped>

/*右边用户信息css*/
.v_upinfo {
  display: flex;
  height: 58px;
  margin-bottom: 6px;
}

.bili-avatar {
  margin-right: 10px;
  display: flex;
}

.img {
  margin: auto;
  height: 48px;
  width: 48px;
  /*display: block;*/
}

.up-info_right div {
  text-align: left;
  line-height: 24px;
  height: 24px;
}

.username {
  font-size: 16px;
}


/*下面的框子*/
.page {
  background: #F1F2F3;

}

.page div:hover {
  /*background-color: #0078ff;*/
  /*color: #ffffff;*/
  color: red;
}

.blue {
  background-color: #0078ff;
  color: #ffffff;
}
.white{
  /*background-color: #fff;*/
}
.page-li {
  display: block;
  padding:0 5px;
  text-align: left;
  width: calc(100% - 10px);
  height: 2.5rem;
  line-height: 2.5rem;
  overflow: hidden;
  margin: 3px auto;
}

.page .title {
  margin-left: 10px;
  overflow: hidden;
  width: 100%;
}

.show {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
}
</style>