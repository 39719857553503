import service from "@/utils/request";

/**
 * 点赞
 * @param province
 * @returns {AxiosPromise}
 */
export function good(good) {
    return service({
        url: 'good/good',
        method: 'post',
        data:good
    })
}

