import service from "@/utils/request";

/**
 * 根据的视屏id ，来获取视屏id
 * @param id 视屏id
 * @returns {AxiosPromise}
 */
export function getPlayUrl(id){
    return service(
        {
            url: 'video/getPlayUrl/'+id,
            method: 'get',
        })
}

/**
 * 获取播放网址
 * @param idList
 * @returns {AxiosPromise}
 */
export function getPlayUrlList(idList){
    return service(
        {
            url: 'video/getPlayUrlList/',
            method: 'get',
            params:{idList}
        })
}
