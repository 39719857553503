<template>
  <div>
    <!--    展示所有评论-->
    <el-row v-for="(item,index) in text" :key="index" class="pd" type="flex">
      <div class="left">
        <div class="div-avator">
          <el-avatar class="da" :src="item.userImg"></el-avatar>
        </div>
      </div>
      <div class="right">
        <!--          评论内容-->
        <div class="name">
          {{ item.username }}
        </div>
        <div class="content">
          {{ item.commentContent }}
        </div>
        <div class="good">
          <div>
            <i class="fa fa-clock-o"></i>
            {{ item.gmtCreate }}
          </div>
          <div>
            <i class="fa fa-thumbs-o-up"></i>
            {{ item.commentGood }}
          </div>
          <div @click="reply(item)">
            回复
          </div>
        </div>
        <el-row v-if="item.voCommentList!=null&&item.voCommentList.length>0" class="child">
          <comment-two :text="item.voCommentList" :type="type"></comment-two>
        </el-row>

        <el-dialog :visible.sync="dialogVisible" v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose"
                   title="评论">
          <el-form ref="elForm" :model="comment" :rules="commentRules" size="medium" label-width="100px"
                   label-position="left">
            <el-form-item label="请填写评论" prop="value">
              <el-input v-model="comment.value" type="textarea" placeholder="输入请请填写评论"
                        :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handelConfirm">评论</el-button>
          </div>
        </el-dialog>
      </div>
    </el-row>
  </div>
</template>

<script>
import CommentTwo from "@/components/comment/comment-two";
import {addComment} from "@/api/comment";

export default {
  name: "comment-info",
  components: {CommentTwo},
  props: {
    text: {
      type: Array,
      default() {
        return []
      }
    },
    type: Number
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
      comment: {
        value: undefined,
        field102: undefined,
      },
      commentRules: {
        value: [{
          required: true,
          message: '输入请请填写评论',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    reply(item) {
      console.log(this)
      this.id = item.id;
      this.dialogVisible = true;
    },
    onOpen() {
    },
    onClose() {
    },
    close() {
      this.dialogVisible = false;
    },
    handelConfirm() {
      if (this.comment.value === undefined || this.comment.value === null) {
        this.dialogVisible = false;
        return
      }
      if (this.$store.state.user.id !== undefined && this.$store.state.user.id !== "") {
        let courseComment = {
          userId: this.$store.state.user.id,
          commentContent: this.comment.value,
          commentPid: this.id,
          level: 2,
          type:this.type
        }
        addComment(courseComment).then(result => {
          this.$message(result.data.article);
          this.value = ""
          // 重新刷洗一下评论信息
          this.$emit("reply")
        }).catch(error => {
          this.$message(error.message)
        })
      } else {
        this.$message("请先登录，用户id 为空值")
      }
      this.dialogVisible = false;
    },
  }
}
</script>

<style scoped>
/* 读评论的css */
.pd {
  margin: 10px auto;
  width: 100%;
  padding: 2px;
}

/* 左边*/
.div-avator {
  display: flex;
}

.left {
  width: 50px;
  margin-right: 10px;
}

/* 右边*/
.right {
  width: 100%;
}

.da {
  height: 40px;
  width: 40px;
  margin: auto;
}

.name {
  text-align: left;
  color: #fb7299 !important;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  margin: auto;
}

.content {
  text-align: left;
  margin: 5px auto 10px;
  font-size: 15px;
  text-shadow: none;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  color: #18191C;
  font-family: PingFang SC, HarmonyOS_Regular, Helvetica Neue, Microsoft YaHei, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}


/* 点赞*/
.good {
  height: 20px;
  line-height: 20px;
  display: flex;
}

.good div {
  /*width: 50px;*/
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
}

/* 子评论*/
.child {
  margin-top: 20px;
}
</style>