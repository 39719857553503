<template>
  <div>
    <div v-for="(item,index) in text" :key="index" class="pd">
      <el-row  type="flex">
        <div class="left">
          <div class="div-avator">
            <el-avatar class="da" :src="item.userImg"></el-avatar>
          </div>
        </div>
        <div class="right">
          <!--          评论内容-->
          <div class="right-top">
            <div class="name">
              {{ item.username }}
            </div>

            <div class="content">
              {{ item.commentContent }}
            </div>
          </div>
          <div class="good">
            <div>
              <i class="fa fa-clock-o"></i>
              {{ item.gmtCreate }}
            </div>
            <div>
              <i class="fa fa-thumbs-o-up"></i>
              {{ item.commentGood }}
            </div>
            <div @click="reply(item)">
              回复
            </div>
          </div>
        </div>
      </el-row>
      <el-row v-if="item.voCommentList!= null && item.voCommentList.length>0" class="pd" >
        <el-row v-for="(item3,index3) in item.voCommentList" :key="index3" type="flex">
          <div class="left">
            <div class="div-avator">
              <el-avatar class="da" :src="item3.userImg"></el-avatar>
            </div>
          </div>
          <div class="right">
            <!--          评论内容-->
            <div class="right-top">
              <div class="name">
                {{ item3.username }}
              </div>
              <div class="reply" >
                回复<span>{{item.username}}</span>
              </div>
              <div class="content">
                {{ item3.commentContent }}
              </div>
            </div>
            <div class="good">
              <div>
                <i class="fa fa-clock-o"></i>
                {{ item3.gmtCreate }}
              </div>
              <div>
                <i class="fa fa-thumbs-o-up"></i>
                {{ item3.commentGood }}
              </div>
            </div>
          </div>
        </el-row>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose"
               title="评论">
      <el-form ref="elForm" :model="comment" :rules="commentRules" size="medium" label-width="100px"
               label-position="left">
        <el-form-item label="请填写评论" prop="value">
          <el-input v-model="comment.value" type="textarea" placeholder="输入请请填写评论"
                    :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">评论</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addComment} from "@/api/comment";
export default {
  name: "comment-two",
  props: {
    text: {
      type: Array,
      default() {
        return []
      }
    },
    type: Number
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
      comment: {
        value: undefined,
        field102: undefined,
      },
      commentRules: {
        value: [{
          required: true,
          message: '输入请请填写评论',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    reply(item) {
      console.log(this)
      this.id = item.id;
      this.dialogVisible = true;
    },
    onOpen() {
    },
    onClose() {
    },
    close(){
      this.dialogVisible=false;
    },
    handelConfirm() {
      if (this.comment.value===undefined ||this.comment.value===null) {
        this.dialogVisible=false;
        return
      }
      if (this.$store.state.user.id !== undefined && this.$store.state.user.id !== "") {
        let courseComment = {
          userId: this.$store.state.user.id,
          commentContent: this.comment.value,
          commentPid: this.id,
          level: 3,
          type:this.type,
        }
        addComment(courseComment).then(result => {
          this.$message(result.data.article);
          this.value = ""
          // 重新刷洗一下评论信息
          // this.$emit("reply")
        }).catch(error => {
          this.$message(error.message)
        })
      } else {
        this.$message("请先登录，用户id 为空值")
      }
      this.dialogVisible=false;
    },
  }
}
</script>

<style scoped>
/* 读评论的css */
.pd {
  margin: 10px auto;
  width: 100%;
  padding: 2px;
}

/* 左边*/
.div-avator {
  display: flex;
}

.left {
  width: 40px;
  margin-right: 10px;

}

/* 右边*/
.right {
  width: 100%;
}

.da {
  height: 30px;
  width: 30px;
  margin: auto;
}

.right-top {
  display: flex;
}

.name {
  text-align: left;
  color: #fb7299 !important;
  height: 20px;
  line-height: 20px;
  margin-right: 10px;
}
.reply{
  margin-right: 10px;
  height: 20px;
  line-height: 20px;

}
.reply span{
  color: #fb7299;
}
.content {
  text-align: left;
  font-size: 15px;
  text-shadow: none;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  color: #18191C;
  font-family: PingFang SC, HarmonyOS_Regular, Helvetica Neue, Microsoft YaHei, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  margin-left: 10px;
}


/* 点赞*/
.good {
  height: 20px;
  line-height: 20px;
  display: flex;
}

.good div {
  /*width: 50px;*/
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-right: 10px;
}

/* 子评论*/
.child {
  margin-top: 10px;
}
</style>