<template>
  <div class="show">
    <!--    标题-->
    <el-row :gutter="20">
      <!--     左边-->
      <el-col :span="18">
        <left :course="course" :url="url" :id="id" ></left>
      </el-col>
      <!--     右边-->
      <el-col :span="6" class="right">
        <right v-if="video" @clickUrl="change" :id="videoId" :video="video" :user="user"></right>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import {getCourse} from "@/api/course";
import {getPlayUrl} from "@/api/vod";
import Left from "@/views/video/show/left";
import Right from "@/views/video/show/right";

export default {
  name: "videoShow",
  components: {Right, Left},
  data() {
    return {
      course: "",
      //
      url: "",
      // 文章的id
      id: this.$route.params.id,
      video: [],
      user: {},
      videoId: ""
    }
  },
  mounted() {
    // 获取视屏信息
    getCourse(this.id).then(result => {
      console.log(result)
      this.user = {
        username: result.data.course.username,
        userImg: result.data.course.userImg,
        userIntroduce: result.data.course.userIntroduce
      };
      this.course = result.data.course.course;
      this.video = result.data.course.videoList;
      // this.tags = result.data.course.tags;
      this.change(this.video[0].id);
    })
  },
  methods: {
    // 根据视屏的id ，来获取视屏信息
    change(id) {
      this.videoId = id;
      let that = this;
      getPlayUrl(id).then(result => {
        that.url = result.data.url;
        console.log(that.url)
      }).catch(error => {
        getPlayUrl(id).then(result => {
          that.url = result.data.url;
        }).catch(error => {
          that.$message({
            type: "error",
            duration: 2 * 1000,
            message: "对不起，请求失败，请重新点击"
          })
        })
      })
    }
  },
};
</script>
<style scoped>
.show {
  padding: 20px;
  background-color: #fff;
  /*width: 85%;*/
  /*margin: auto;*/
}

.el-menu-vertical-demo {

}
</style>
