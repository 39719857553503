import service from "@/utils/request";

/**
 *  收藏
 * @param collect
 * @returns {AxiosPromise}
 */
export function collect(collect) {
    return service({
        url: 'collect/collect',
        method: 'post',
        data:collect
    })
}

/**
 * 获取收藏的课程信息
 * @param userId
 * @param current
 * @returns {AxiosPromise}
 */
export function getCollectByUserId(userId,type,current) {
    return service({
        url: 'collect/getCollectByUserId',
        method: 'post',
        params:{
            userId,type,current
        }
    })
}

// export function collectArticle(userId,current) {
//     return service({
//         url: 'collect/collectArticle',
//         method: 'post',
//         params:{
//             userId,current
//         }
//     })
// }
