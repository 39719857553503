<template>
  <div class="comment">
    <!--      写评论的地方-->
    <el-row type="flex" class="write">
      <div class="ava">
        <el-avatar class="el-ava" :src="this.$store.state.user.avatar"></el-avatar>
      </div>
      <el-row class="comment-send">
        <el-col :span="18">
          <el-input class="area" type="textarea" v-model="value"></el-input>
        </el-col>
        <el-col :span="6" class="col-6">
          <el-button type="primary" @click="addComment" class="btn">发送评论</el-button>
        </el-col>
      </el-row>
    </el-row>
    <!--     评论了-->
    <comment-info :text="comment" @reply="getAllComment" :type="type"></comment-info>
  </div>
</template>

<script>
import {getComment, addComment} from "@/api/comment";
import CommentInfo from "@/components/comment/comment-info";

export default {
  name: "comment",
  components: {CommentInfo},
  props: {
    id: String,
    type: Number

  },
  data() {
    return {
      comment: [],
      value: "",
      // type: 1,
    }
  },
  mounted() {
    this.getAllComment();
  },
  methods: {
    // 获取所有评论
    getAllComment() {
      // id为文章的id
      getComment(this.id).then(result => {
        console.log(result.data.comment)
        this.comment = result.data.comment;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    // 添加评论
    addComment() {
      if (this.value === undefined || this.value === "") {
        this.$message("请输入评论信息")
        return
      }
      if (this.id === undefined || this.id === "") {
        return;
      }
      if (this.$store.state.user.id !== undefined && this.$store.state.user.id !== "") {
        let courseComment = {
          userId: this.$store.state.user.id,
          commentContent: this.value,
          commentPid: this.id,
          level: 1,
          type: this.type
        }
        addComment(courseComment).then(result => {
          this.$message(result.data.article);
          this.value = ""
          // 重新刷洗一下评论信息
          this.getAllComment();
        }).catch(error => {
          this.$message(error.message)
        })
      } else {
        this.$message("请先登录，用户id 为空值")
      }
    }
  }
}
</script>

<style scoped>
/* 总体的css*/
.comment {
  margin: 10px auto;
  background-color: #fff;
}

/*  写评论的 css*/
.write {
  margin-bottom: 20px;
  height: 60px;
  line-height: 60px;
}

.btn {
  margin: 0 auto;
  /*height: 40px;*/
}

.area {
  height: 100%;
}

.ava {
  height: 50px;
  line-height: 60px;
  display: flex;
  margin-right: 10px;
}

.el-ava {
  margin: auto;
  height: 40px;
  width: 40px;
}

.comment-send {
  width: 100%;
}
</style>
