<template>
  <div>
    <!--       标签-->
    <el-row class="viewbox_report">
      <h1 class="courseName">{{ course.courseName }}</h1>
      <el-row class="row">
        <div>
          <i class="fa fa-play-circle"></i>
          <span> {{ course.courseViews }} 播放量</span>
        </div>
        <div>
          <i class="fa fa-clock-o"></i>
          <span> {{ course.gmtCreate }}</span>
        </div>
      </el-row>
    </el-row>
    <!--        视频-->
    <el-row>
      <video controls class="video" :src="url"></video>
    </el-row>

    <!--       收藏-->
    <el-row class="video-toolbar-v1" type="flex">
      <el-col :span="3" @click.native="clickGood">
        <i class="fa fa-thumbs-o-up"></i>
        <span>{{ course.courseGood }}</span>
        <!--            <span>点赞</span>-->
      </el-col>
      <el-col :span="3">
        <i class="fa fa-thumbs-o-down"></i>
        <span>{{ course.courseBad }}</span>
      </el-col>
      <el-col :span="3" @click.native="clickCollect">
        <i class="el-icon-star-off"></i>
        <span>{{ course.courseStar }}</span>
      </el-col>
      <el-col :span="3">
        <i class="fa fa-share-square-o"></i>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-descriptions :colon="false" :column="1">
      <el-descriptions-item >
        {{ course.courseIntroduction }}
      </el-descriptions-item>
      <el-descriptions-item >
        <span v-if="course.courseType===1">自制</span>
        <span v-else>转载</span>
      </el-descriptions-item>
      <el-descriptions-item >
        <el-tag effect="plain" type="primary" class="tag" v-for="(item,index) in course.tags.split(',')" :key="index">
          {{ item }}
        </el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <el-row>
      <comment v-if="id" class="comment" :id="id" :type="2"></comment>-->
    </el-row>
  </div>
</template>

<script>
import comment from "@/components/comment/Comment";
import {collect} from "@/api/collect";
import {utc2beijing} from "@/utils/time";
import {good} from "@/api/good";

export default {
  name: "left",
  props: ["course", "url", "id"],
  components: {
    comment
  },
  mounted() {
    console.log(this.course)
  },
  data() {
    return {}
  },
  methods: {
    // 点赞
    clickGood() {
      console.log("点赞了")
      let go = {
        userId: this.$store.state.user.id,
        pid: this.id,
        type: 2
      }
      good(go).then(result => {
        this.$message({
          type: "success",
          duration: 2 * 1000,
          message: result.data.good
        })
      }).catch(error => {
        this.$message({
          type: "error",
          duration: 2 * 1000,
          message: error.message
        })
      })
    },
    // 点击收藏
    clickCollect() {
      let co = {
        userId: this.$store.state.user.id,
        pid: this.id,
        type: 2
      }
      collect(co).then(result => {
        this.$message({
          type: "success",
          duration: 2 * 1000,
          message: result.data.collect
        })
      }).catch(error => {
        this.$message({
          type: "error",
          duration: 2 * 1000,
          message: error.message
        })
      })
    },
    utc2beijing,
  }
}
</script>

<style scoped>
/*课程标签名*/
.courseName {
  font-size: 20px;
  font-family: PingFang SC, HarmonyOS_Regular, Helvetica Neue, Microsoft YaHei, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  line-height: 24px;
  margin-bottom: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 24px;
  text-align: left;
}

.row {
  height: 24px;
  display: flex;
}

.row div {
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
}

.viewbox_report {
  margin-bottom: 10px;
}

/*收藏*/
.video-toolbar-v1 {
  height: 30px;
  text-align: center;
  font-size: 1.25rem;
  margin: 10px auto 10px;
}

.video-toolbar-v1 div {
  height: 30px;
  line-height: 30px;
  text-align: left;
}

.video-toolbar-v1 i {
  margin-right: 5px;
}

.type {
  text-align: left;
  margin-bottom: 10px;
}


/*标签的css*/
.tag-wrap {
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}

.tag-wrap div {
  height: 30px;
  line-height: 30px;
}


.tag {
  margin-right: 10px;
}


.comment {
  margin-top: 10px;
}

.good .title {
  display: block;
  height: 6.25rem;
  width: 10%;
  float: left;
}

.video {
  height: auto;
  min-height: 400px;
  width: 100%;
  margin: auto;
  border: 1px solid black;
}

</style>