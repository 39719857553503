import service from "@/utils/request";

/**
 * 根据id 来获取评论
 * @param id 文章或者博客id
 * @returns {AxiosPromise}
 */
export function getComment(id){
    return service(
        {
            url: 'comment/selectById',
            method: 'post',
            params:{id}
        })
}

/**
 * 添加评论信息
 * @param id
 * @returns {AxiosPromise}
 */
export function addComment(courseComment){
    return service(
        {
            url: 'comment/addComment',
            method: 'post',
            header:{
                "Content-Type":"application/json" //如果写成contentType会报错
            },
            data:courseComment
        })
}
